var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-simple-table', {
          staticClass: "mt-5",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_vm.livingExpensesCopy.length > 0 ? _c('tbody', _vm._l(_vm.livingExpenses, function (livingExpense, idx) {
                return _c('tr', {
                  key: idx
                }, [_c('td', [_c('v-combobox', {
                  staticClass: "pa-5",
                  attrs: {
                    "items": _vm.descriptionOptions,
                    "label": "Description",
                    "dense": "",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  model: {
                    value: livingExpense.description,
                    callback: function callback($$v) {
                      _vm.$set(livingExpense, "description", $$v);
                    },
                    expression: "livingExpense.description"
                  }
                })], 1), _c('td', [_c('v-text-field', {
                  staticClass: "pa-5",
                  attrs: {
                    "dense": "",
                    "type": "text",
                    "placeholder": "Amount, $",
                    "label": "Amount, $",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  on: {
                    "focus": function focus($event) {
                      var _vm$livingExpensesCop, _vm$livingExpensesCop2;

                      return _vm.inputNumberType($event, (_vm$livingExpensesCop = _vm.livingExpensesCopy) === null || _vm$livingExpensesCop === void 0 ? void 0 : (_vm$livingExpensesCop2 = _vm$livingExpensesCop[idx]) === null || _vm$livingExpensesCop2 === void 0 ? void 0 : _vm$livingExpensesCop2.amount, idx);
                    },
                    "blur": function blur($event) {
                      var _vm$livingExpensesCop3, _vm$livingExpensesCop4;

                      return _vm.inputTextType($event, (_vm$livingExpensesCop3 = _vm.livingExpensesCopy) === null || _vm$livingExpensesCop3 === void 0 ? void 0 : (_vm$livingExpensesCop4 = _vm$livingExpensesCop3[idx]) === null || _vm$livingExpensesCop4 === void 0 ? void 0 : _vm$livingExpensesCop4.amount, idx);
                    }
                  },
                  model: {
                    value: _vm.livingExpensesCopy[idx].amount,
                    callback: function callback($$v) {
                      _vm.$set(_vm.livingExpensesCopy[idx], "amount", $$v);
                    },
                    expression: "livingExpensesCopy[idx].amount"
                  }
                })], 1), _c('td', [_c('v-select', {
                  staticClass: "pa-5",
                  attrs: {
                    "dense": "",
                    "items": _vm.periodOptions,
                    "label": "Period",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  model: {
                    value: livingExpense.period,
                    callback: function callback($$v) {
                      _vm.$set(livingExpense, "period", $$v);
                    },
                    expression: "livingExpense.period"
                  }
                })], 1), _c('td', [_c('v-icon', {
                  attrs: {
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  on: {
                    "click": function click($event) {
                      $event.preventDefault();
                      return _vm.removeLivingExpense(idx);
                    }
                  }
                }, [_vm._v(" mdi-delete ")])], 1)]);
              }), 0) : _vm._e(), _c('br'), _c('p', {
                staticClass: "month-total"
              }, [_vm._v("Monthly total: $ " + _vm._s(_vm.expensesTotal))]), _c('br')];
            },
            proxy: true
          }])
        }), _c('v-btn', {
          attrs: {
            "color": "primary",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "click": _vm.addLivingExpense
          }
        }, [_vm._v(" Add new ")])], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }