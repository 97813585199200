<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-simple-table class="mt-5">
            <template #default>
              <tbody v-if="livingExpensesCopy.length > 0">
                <tr
                  v-for="(livingExpense, idx) in livingExpenses"
                  :key="idx"
                >
                  <td>
                    <v-combobox
                      v-model="livingExpense.description"
                      :items="descriptionOptions"
                      label="Description"
                      class="pa-5"
                      dense
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-text-field
                      dense
                      type="text"
                      v-model="livingExpensesCopy[idx].amount"
                      @focus="inputNumberType($event, livingExpensesCopy?.[idx]?.amount, idx)"
                      @blur="inputTextType($event, livingExpensesCopy?.[idx]?.amount, idx)"
                      placeholder="Amount, $"
                      label="Amount, $"
                      class="pa-5"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-select
                      dense
                      v-model="livingExpense.period"
                      :items="periodOptions"
                      label="Period"
                      class="pa-5"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-icon @click.prevent="removeLivingExpense(idx)" :disabled="FIELDS_DISABLED">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
              <br>
              <p class="month-total">Monthly total: $ {{ expensesTotal }}</p>
              <br>
            </template>
          </v-simple-table>
          <v-btn color="primary" @click="addLivingExpense" :disabled="FIELDS_DISABLED">
            Add new
          </v-btn>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';
import SummaryMixin from '@/mixins/SummaryMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'LivingExpensesSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin, SummaryMixin],

  props: {
    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      descriptionOptions: [
        'Rent',
        'Body Corporate Fees',
        'Child support/maintance',
        'KiwiSaver',
        'Food',
        'Utilities',
        'Rates',
        'Transport',
        'Ongoing Household Expenses',
        'Childcare',
        'Healthcare/Medical Care',
        'Personal Care and Clothing',
        'Public education',
        'Private education',
        'House and Contents insurance',
        'Vehicle Insurance',
        'Health/ Medical/ Funeral/ Pet Insurance',
        'Life and Disability Insurance',
        'Savings/ Investments',
        'Entertainmant and Recreation',
        'Tithing',
        'Other',
      ],
      periodOptions: ['Week', 'Fortnight', 'Month', 'Year'],

      isSectionVisible: false,

      initialLivingExpenses: [],
      livingExpenses: [],

      livingExpensesCopy: [],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialLivingExpenses = clone(this.existedData);
    this.livingExpenses = clone(this.existedData);

    this.livingExpensesCopy = clone(this.livingExpenses);

    this.livingExpensesCopy.forEach((item, index) => {
      this.livingExpensesCopy[index].amount = this.$options.filters.integerFormatter(item.amount);
    });

    this.$emit('componentReady');
  },

  methods: {
    addLivingExpense() {
      const companyData = {
        description: '',
        amount: '',
        period: '',
      };

      this.livingExpenses.push(companyData);
      this.livingExpensesCopy = clone(this.livingExpenses);

      this.livingExpensesCopy.forEach((item, index) => {
        this.livingExpensesCopy[index].amount = this.$options.filters.integerFormatter(item.amount);
      });
    },

    removeLivingExpense(idxLivingExpense) {
      this.livingExpenses.splice(idxLivingExpense, 1);
      this.livingExpensesCopy = clone(this.livingExpenses);

      this.livingExpensesCopy.forEach((item, index) => {
        this.livingExpensesCopy[index].amount = this.$options.filters.integerFormatter(item.amount);
      });
    },

    inputTextType(event, value, index) {
      const target = event?.target;
      target.type = 'text';
      this.livingExpensesCopy[index].amount = this.$options.filters.integerFormatter(value);
      this.livingExpenses[index].amount = this.$options.filters.textFormatter(value);
    },

    inputNumberType(event, value, index) {
      const target = event?.target;
      target.type = 'number';

      this.livingExpensesCopy[index].amount = this.$options.filters.textFormatter(value);
    },
  },
};
</script>

<style scoped>
.month-total {
  font-size: 16px;
  font-weight: 600;
}
</style>
